import { jwtDecode } from "jwt-decode";
import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { format, formatISO, parseISO } from "date-fns";
import axios from "axios";

// interface AuthUserObject extends JwtPayload {
//   username: string;
//   roles: string[];
//   name: string;
// }

export const axiosConfig = {
  baseURL: import.meta.env.VITE_ALLOWED_API,
  timeout: 10000,
  withCredentials: true,
};

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const formatDate = (date: string) => {
  if (!date) {
    return ""; // Handle null or undefined dates as needed
  }
  return format(new Date(date), "dd/MM/yyyy HH:mm");
};

export const formatDOB = (date: string) => {
  if (!date) {
    return ""; // Handle null or undefined dates as needed
  }
  return format(new Date(date), "dd/MM/yyyy");
};

export const InputDateFormat = (dateString: string) => {
  if (!dateString) return "";
  return formatISO(parseISO(dateString), { representation: "date" });
};

export const serverErrorMessage = (error: any) => {
  let err_message = "";
  if (error && error.response && error.response.data) {
    err_message = error.response.data.message;
  } else if (error && !error.response) {
    err_message = error.message;
  } else {
    err_message = "Error Occured";
  }
  return err_message;
};

export const decodeJwtToken = (token: string) => {
  if (!token) return null;
  return jwtDecode(token);
};

export const isTokenExpired = (token: string) => {
  try {
    const decodedToken = jwtDecode(token);
    if (!decodedToken.exp) return true;
    const currentTime = Date.now() / 1000; // Get current time in seconds
    return decodedToken.exp < currentTime;
  } catch (e) {
    return true; // If there's an error decoding the token, consider it expired
  }
};

export const refreshToken = async () => {
  try {
    const axiosInstance = axios.create(axiosConfig);
    const res = await axiosInstance.post("/auth/refresh-token", {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    const authHeader = res.headers["authorization"];
    if (!authHeader || !authHeader.startsWith("Bearer ")) {
      throw Error("Authorization token missing");
    }
    const token = authHeader.split(" ")[1];
    if (!token) throw Error("Authorization token missing");
    return token;
  } catch (error) {
    console.log("refresh_token_error", error);
    throw error;
  }
};

export const fileSize = (size: number) => {
  const units = ["B", "KB", "MB", "GB", "TB"];
  let sizeInBytes = size;
  let unitIndex = 0;
  while (sizeInBytes >= 1024 && unitIndex < units.length - 1) {
    sizeInBytes /= 1024;
    unitIndex++;
  }
  return `${sizeInBytes.toFixed(2)} ${units[unitIndex]}`;
};
