import { createColumnHelper, ColumnDef } from "@tanstack/react-table";
import { formatDate } from "@/lib/utils";
import DeleteReminder from "@/components/Forms/Lead/DeleteReminder";
import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { EyeIcon } from "lucide-react";
import { format } from "date-fns";
import { Checkbox } from "@/components/ui/checkbox";

type Reminder = {
  _id: string;
  title: string;
  followup: string;
  status: string;
  user: {
    userId: string;
    first_name: string;
    last_name: string;
    email: string;
  };
  addedBy: {
    first_name: string;
    last_name: string;
  };
  createdAt: string;
  updatedAt: string;
};

const columnHelper = createColumnHelper<Reminder>();

export const ReminderCols = (authUserRole: string): ColumnDef<any, any>[] => [
  columnHelper.display({
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={
          table.getIsAllPageRowsSelected() ||
          (table.getIsSomePageRowsSelected() && "indeterminate")
        }
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
      />
    ),
    cell: (props) => (
      <Checkbox
        checked={props.row.getIsSelected()}
        onCheckedChange={(value) => props.row.toggleSelected(!!value)}
        aria-label="Select row"
      />
    ),
  }),
  columnHelper.accessor("title", {
    header: "Reminder Title",
  }),
  columnHelper.accessor(
    (row) =>
      row.followup
        ? format(new Date(row.followup), "MMM d, yyyy, hh:mm a")
        : "",
    {
      header: "Followup",
    }
  ), // Display Column
  // Accessor Column
  columnHelper.accessor((row) => row.status, {
    id: "status",
    header: "Status",
    cell: (info) => (
      <Button
        className={getStatusClass(info.getValue()) + " capitalize font-normal"}
      >
        {info.getValue()}
      </Button>
    ),
  }),
  // columnHelper.accessor(row.status, {
  //   header: "Status",
  // }),
  columnHelper.accessor(
    (row) => `${row.user?.first_name} ${row.user?.last_name}`,
    {
      header: "User",
    }
  ),
  columnHelper.accessor((row) => `${row.user.email} `, {
    header: "User Email",
  }),
  columnHelper.accessor(
    (row) => {
      return row.addedBy
        ? `${row?.addedBy?.first_name} ${row?.addedBy?.last_name}`
        : "";
    },
    {
      header: "Added By",
    }
  ),
  columnHelper.accessor((row) => formatDate(row.createdAt), {
    header: "Added On",
  }),
  columnHelper.accessor((row) => formatDate(row.updatedAt), {
    header: "Updated On",
  }),
  columnHelper.accessor((row) => row.user?.userId, {
    id: "View",
    cell: (info) => (
      <Link to={`/crm-leads/lead/${info.getValue()}/reminders`}>
        <Button
          variant={`outline`}
          className="text-primary hover:bg-primary hover:text-white"
        >
          <EyeIcon className="w-4 h-4 mr-2" /> View
        </Button>
      </Link>
    ),
  }),
  columnHelper.accessor((row) => row._id, {
    id: "Delete",
    cell: (info) => {
      return authUserRole === "admin" || authUserRole === "master" ? (
        <DeleteReminder recordId={info.getValue()} />
      ) : null;
    },
  }),
];

const statusClasses: Record<string, string> = {
  pending: "bg-yellow-100 hover:bg-yellow-300/90 text-yellow-800",
  completed: "bg-green-100 hover:bg-green-300/90 text-green-800",
  overdue: "bg-red-200 hover:bg-red-400/90 text-red-800",
};

const getStatusClass = (status: string): string => statusClasses[status] || "";
