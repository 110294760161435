import { useTokenStore } from "@/store/auth.store";
import { useEffect, useState } from "react";

// Define the expected roles
type Role = "master" | "admin" | "employee" | "";

// Define the structure of the decoded token props
type decodeTokenProps = {
  name: string;
  username: string;
  roles: string[]; // General array of roles
};

const useAuthUserRole = (
  decodedToken: decodeTokenProps | null // Token might be null
): Role => {
  const { deleteToken } = useTokenStore();
  const [authUserRole, setAuthUserRole] = useState<Role>("");

  useEffect(() => {
    if (decodedToken && Array.isArray(decodedToken.roles)) {
      // Define the valid roles and their priority
      const validRoles: Role[] = ["master", "admin", "employee"];
      const userRole: Role =
        validRoles.find((role) => decodedToken.roles.includes(role)) || "";

      if (userRole) {
        setAuthUserRole(userRole);
      } else {
        deleteToken(); // Clear token if no valid role is found
      }
    } else {
      deleteToken(); // Clear token if roles are missing or invalid
    }
  }, [decodedToken, deleteToken]);

  return authUserRole;
};

export default useAuthUserRole;
