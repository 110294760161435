import {
  BellPlusIcon,
  DownloadIcon,
  FileClockIcon,
  GlobeIcon,
  HistoryIcon,
  IndianRupeeIcon,
  LayoutDashboardIcon,
  ListChecks,
  NetworkIcon,
  PhoneOutgoingIcon,
  User2Icon,
  UserCheck,
  AreaChart,
  UploadCloudIcon,
} from "lucide-react";

const webMenu = [
  {
    menu_title: "Web Leads",
    menu_link: "/web-leads",
    icon: <GlobeIcon className="w-4 h-4 mr-2" />,
  },
  {
    menu_title: "Eligibility Leads",
    menu_link: "/eligibility-leads",
    icon: <ListChecks className="w-4 h-4 mr-2" />,
  },
  {
    menu_title: "Import Leads",
    menu_link: "/channel-leads",
    icon: <NetworkIcon className="w-4 h-4 mr-2" />,
  },
];

const crmMenu = [
  {
    menu_title: "Users",
    menu_link: "/crm-leads",
    icon: <UserCheck className="w-4 h-4 mr-2" />,
  },
  {
    menu_title: "Calls",
    menu_link: "/calls",
    icon: <PhoneOutgoingIcon className="w-4 h-4 mr-2" />,
  },
  {
    menu_title: "Reminders",
    menu_link: "/reminders",
    icon: <BellPlusIcon className="w-4 h-4 mr-2" />,
  },
  {
    menu_title: "Payments",
    menu_link: "/payments",
    icon: <IndianRupeeIcon className="w-4 h-4 mr-2" />,
  },
];

const empManageMenu = [
  {
    menu_title: "Employees",
    menu_link: "/employees",
    icon: <User2Icon className="w-4 h-4 mr-2" />,
  },
  {
    menu_title: "Employee Activity",
    menu_link: "/employee-activity",
    icon: <HistoryIcon className="w-4 h-4 mr-2" />,
  },
  {
    menu_title: "Time Tracker",
    menu_link: "/time-tracker",
    icon: <FileClockIcon className="w-4 h-4 mr-2" />,
  },
];

const dashboardMenu = [
  {
    menu_title: "Dashboard",
    menu_link: "/dashboard",
    icon: <LayoutDashboardIcon className="w-4 h-4 mr-2" />,
  },
  {
    menu_title: "Reports",
    icon: <AreaChart className="w-4 h-4 mr-2" />,
    submenu: [
      {
        menu_title: "All Reports",
        menu_link: "/reports",
        icon: <DownloadIcon className="w-4 h-4 mr-2" />,
      },
      {
        menu_title: "Leads Status Report",
        menu_link: "/lead-status-report",
        icon: <DownloadIcon className="w-4 h-4 mr-2" />,
      },
      {
        menu_title: "Calls Report",
        menu_link: "/calls-report",
        icon: <DownloadIcon className="w-4 h-4 mr-2" />,
      },
      {
        menu_title: "Reminders Report",
        menu_link: "/reminders-report",
        icon: <DownloadIcon className="w-4 h-4 mr-2" />,
      },
      {
        menu_title: "Employee Activity Report",
        menu_link: "/employee-activity-report",
        icon: <DownloadIcon className="w-4 h-4 mr-2" />,
      },
    ],
  },
  {
    menu_title: "File Uploads",
    menu_link: "/file-uploads",
    icon: <UploadCloudIcon className="w-4 h-4 mr-2" />,
  },
];

export { webMenu, crmMenu, empManageMenu, dashboardMenu };
