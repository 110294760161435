import { Checkbox } from "@/components/ui/checkbox";
import { formatDate } from "@/lib/utils";
import { createColumnHelper } from "@tanstack/react-table";

export type TChannelLead = {
  _id: string;
  full_name: string;
  email: string;
  phone_number: string;
  form_name: string;
  campaign_name: string;
  adset_name: string;
  ad_name: string;
  createdAt: string;
  isProcessed: boolean;
};

const columnHelper = createColumnHelper<TChannelLead>();

export const ChanhannelLeadsCols = [
  columnHelper.display({
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={
          table.getIsAllPageRowsSelected() ||
          (table.getIsSomePageRowsSelected() && "indeterminate")
        }
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
      />
    ),
    cell: (props) => (
      <Checkbox
        checked={props.row.getIsSelected()}
        onCheckedChange={(value) => props.row.toggleSelected(!!value)}
        aria-label="Select row"
      />
    ),
  }),
  columnHelper.accessor("full_name", {
    header: "Full Name",
    enableColumnFilter: false,
  }),
  columnHelper.accessor("email", {
    header: "Email",
    enableColumnFilter: false,
  }),
  columnHelper.accessor("phone_number", {
    header: "Phone Number",
    enableColumnFilter: false,
  }),
  columnHelper.accessor("form_name", {
    header: "Form Name",
    enableColumnFilter: false,
  }),
  columnHelper.accessor("campaign_name", {
    header: "Campaign Name",
    enableColumnFilter: false,
  }),
  columnHelper.accessor("ad_name", {
    header: "Ad Name",
    enableColumnFilter: false,
  }),
  columnHelper.accessor("adset_name", {
    header: "AdSet Name",
    enableColumnFilter: false,
  }),
  columnHelper.accessor("createdAt", {
    header: "Created At",
    enableColumnFilter: false,
    cell: (props) => formatDate(props.getValue()),
  }),
  columnHelper.accessor("isProcessed", {
    header: "Processed",
    enableColumnFilter: false,
    cell: (props) => (props.getValue() ? "Yes" : "No"),
  }),
];
