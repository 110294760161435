import { Button } from "@/components/ui/button";
import { useAxiosAuthInstance } from "@/hooks/useAxiosAuthInstance";
import { fileSize, formatDate } from "@/lib/utils";
import { createColumnHelper } from "@tanstack/react-table";
import { toast } from "react-toastify";
import { AxiosInstance } from "axios";

export type TFileUpload = {
  _id: string;
  filename: string;
  size: string;
  path: string;
  isProcessed: boolean;
  processedAt: string;
  addedBy: {
    first_name: string;
    last_name: string;
  };
  createdAt: string;
  processingErrors: string[];
};

const columnHelper = createColumnHelper<TFileUpload>();

export const FileUploadCols = [
  columnHelper.accessor("filename", {
    header: "File Name",
    enableColumnFilter: false,
  }),
  columnHelper.accessor("size", {
    header: "File Size",
    cell: (info) => {
      const bytes = parseInt(info.getValue());
      return fileSize(bytes);
    },
    enableColumnFilter: false,
  }),
  columnHelper.accessor("path", {
    header: "File Path",
    enableColumnFilter: false,
  }),
  columnHelper.accessor(
    (row) => {
      //get first name and last name from addedBy
      return row.addedBy
        ? `${row?.addedBy?.first_name} ${row?.addedBy?.last_name}`
        : "";
    },
    {
      header: "Added By",
      enableColumnFilter: false,
    }
  ),
  columnHelper.accessor("isProcessed", {
    header: "Processed",
    enableColumnFilter: false,
  }),
  columnHelper.accessor("processedAt", {
    header: "Processed At",
    enableColumnFilter: false,
  }),
  columnHelper.accessor("createdAt", {
    header: "Created At",
    cell: (props) => formatDate(props.getValue()),
    enableColumnFilter: false,
  }),
  columnHelper.display({
    header: "Actions",
    cell: (props) => {
      const axiosInstance = useAxiosAuthInstance();

      return (
        <div className="flex gap-2">
          <Button
            className="bg-primary hover:bg-purple-500"
            onClick={() => handleProcess(axiosInstance, props.row.original._id)}
          >
            Process
          </Button>
        </div>
      );
    },
    enableColumnFilter: false,
  }),
  columnHelper.display({
    header: "Errors",
    cell: (props) => {
      if (props.row.original.processingErrors.length === 0) {
        return "No Errors";
      }
      return (
        <Button variant={"danger"} disabled>
          View Errors
        </Button>
      );
    },
    enableColumnFilter: false,
  }),
];

const handleProcess = async (axiosInstance: AxiosInstance, rowId: string) => {
  try {
    const response = await axiosInstance.post("/crm/file-process", {
      id: rowId,
    });
    toast.success(response.data.message);
  } catch (error) {
    toast.error("Error processing file");
  }
};
