import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { LEAD_STATUSES } from "@/data/assessment.data";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { serverErrorMessage } from "@/lib/utils";
import { useAxiosAuthInstance } from "@/hooks/useAxiosAuthInstance";
import { useEffect, useState } from "react";
import useDialog from "@/hooks/useDialog";

type employeeProps = {
  _id: string;
  first_name: string;
  last_name: string;
};

type selectedRecordsProps = {
  selectedRecords: string[];
};

type formDataProps = {
  emp_id: string;
  lead_status: string;
  lead_ids: string[];
};

const UserAssignOrChangeLeadStatus = ({
  selectedRecords,
}: selectedRecordsProps) => {
  const { handleClose } = useDialog();
  const [employees, setEmployees] = useState<employeeProps[]>([]);
  const [selectedEmpId, setSelectedEmpId] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const axiosInstance = useAxiosAuthInstance();
  const { data, isPending, isError } = useQuery({
    queryKey: ["lead_managers"],
    queryFn: async () => await axiosInstance.get(`/admin/lead-managers`),
  });

  useEffect(() => {
    if (!isPending && !isError && data?.data) {
      setEmployees([...data?.data]);
    }
  }, [data?.data, isPending, isError]);

  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async (formData: formDataProps) =>
      await axiosInstance.post("/crm/bulk-assign-leads", formData),
    onSuccess: (res) => {
      setSelectedEmpId("");
      setSelectedStatus("");
      handleClose();
      toast.success(res?.data?.message);
      queryClient.invalidateQueries({
        queryKey: ["users"],
      });
    },
    onError: (error) => {
      toast.error(serverErrorMessage(error));
    },
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("emp", selectedEmpId);
    console.log("lead_status", selectedStatus);
    if (!selectedEmpId && !selectedStatus) {
      toast.error("Please Select Employee or Lead Status");
    } else {
      const data = {
        emp_id: selectedEmpId,
        lead_status: selectedStatus,
        lead_ids: selectedRecords,
      };
      mutation.mutate(data);
    }
  };

  return (
    <div>
      <p className="text-primary text-sm mb-4">
        {selectedRecords.length} Records Selected
      </p>
      <form
        onSubmit={handleSubmit}
        className="flex flex-row items-center gap-4"
      >
        <Select onValueChange={setSelectedEmpId} value={selectedEmpId}>
          <SelectTrigger>
            <SelectValue placeholder="Select Employee" />
          </SelectTrigger>
          <SelectContent>
            {employees.length &&
              employees.map((item) => {
                return (
                  <SelectItem value={item._id} key={item._id}>
                    {item.first_name + " " + item.last_name}
                  </SelectItem>
                );
              })}
          </SelectContent>
        </Select>

        <Select onValueChange={setSelectedStatus} value={selectedStatus}>
          <SelectTrigger>
            <SelectValue placeholder="Select Lead Status" />
          </SelectTrigger>
          <SelectContent>
            {LEAD_STATUSES.map((item, index: number) => {
              return (
                <SelectItem value={item} key={index}>
                  {item}
                </SelectItem>
              );
            })}
          </SelectContent>
        </Select>

        <div className="flex">
          <Button
            type="submit"
            className="bg-primary hover:bg-primary/80 w-full"
          >
            Change
          </Button>
        </div>
      </form>
    </div>
  );
};

export default UserAssignOrChangeLeadStatus;
