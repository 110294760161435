import { UserSchema } from "@/components/FormInterfaces/Assessment";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, SubmitHandler } from "react-hook-form";
import { z } from "zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useAxiosAuthInstance } from "@/hooks/useAxiosAuthInstance";
import { serverErrorMessage } from "@/lib/utils";

const NewLeadForm = () => {
  const axiosInstance = useAxiosAuthInstance();
  const form = useForm<z.infer<typeof UserSchema>>({
    resolver: zodResolver(UserSchema),
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      contact_number: "",
    },
  });

  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async (formData: z.infer<typeof UserSchema>) =>
      await axiosInstance.post(`/users`, formData),
    onSuccess: (res) => {
      toast.success(res?.data?.message);
      queryClient.invalidateQueries({
        queryKey: ["users"],
      });
      form.reset();
    },
    onError: (error: any) => {
      if (error.response?.data?.errors) {
        const validationErrors = error.response.data.errors;

        // Map validation errors to React Hook Form
        validationErrors.forEach((err: any) => {
          form.setError(err.path, { message: err.msg });
        });
      } else {
        toast.error(serverErrorMessage(error));
      }
    },
  });

  const onSubmit: SubmitHandler<z.infer<typeof UserSchema>> = (formData) => {
    mutation.mutateAsync(formData);
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <FormField
          control={form.control}
          name="first_name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>First Name</FormLabel>
              <FormControl>
                <Input placeholder="Enter First Name" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="last_name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Last Name</FormLabel>
              <FormControl>
                <Input placeholder="Enter Last Name" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input placeholder="Enter Email" type="email" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="contact_number"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Contact Number</FormLabel>
              <FormControl>
                <Input
                  placeholder="Enter Contact Number"
                  type="tel"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button type="submit" className="w-full bg-primary hover:bg-primary/80">
          Submit
        </Button>
      </form>
    </Form>
  );
};

export default NewLeadForm;
