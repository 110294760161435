import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormItem,
  FormField,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useAxiosAuthInstance } from "@/hooks/useAxiosAuthInstance";
import { serverErrorMessage } from "@/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm, SubmitHandler } from "react-hook-form";
import { toast } from "react-toastify";
import { z } from "zod";

// Zod validation schema
const fileUploadSchema = z.object({
  file: z
    .instanceof(FileList)
    .refine((files) => files.length === 1, { message: "File is required" })
    .refine((files) => files[0]?.size <= 5 * 1024 * 1024, {
      message: "File size must be less than 5MB",
    })
    .refine(
      (files) =>
        [
          "text/csv",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ].includes(files[0]?.type || ""),
      { message: "Unsupported file type" }
    ),
});

type FileUploadFormValues = z.infer<typeof fileUploadSchema>;

const ImportLeadsForm = () => {
  const axiosInstance = useAxiosAuthInstance();
  const form = useForm({
    resolver: zodResolver(fileUploadSchema),
    defaultValues: {
      file: null as unknown as FileList,
    },
  });

  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async (formData: FileUploadFormValues) => {
      const formDataToSend = new FormData();
      formDataToSend.append("import_leads", formData.file[0]);
      return await axiosInstance.post("/crm/lead-file-upload", formDataToSend);
    },
    onSuccess: (res) => {
      toast.success(res?.data?.message);
      queryClient.invalidateQueries({
        queryKey: ["fileUploads"],
      });
      form.reset();
    },
    onError: (error: any) => {
      if (error.response?.data?.errors) {
        const validationErrors = error.response.data.errors;

        // Map validation errors to React Hook Form
        validationErrors.forEach((err: any) => {
          form.setError(err.path, { message: err.msg });
        });
      } else {
        toast.error(serverErrorMessage(error));
      }
    },
  });

  const onSubmit: SubmitHandler<z.infer<typeof fileUploadSchema>> = (
    formData
  ) => {
    mutation.mutateAsync(formData);
  };

  const selectedFile = form.watch("file");

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-row gap-4 items-baseline"
      >
        <FormField
          control={form.control}
          name="file"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  type="file"
                  accept=".csv, .xlsx"
                  className="h-10" // Set a fixed height
                  onChange={(e) => {
                    field.onChange(e.target.files);
                  }}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button
          type="submit"
          className="self-baseline"
          disabled={mutation.isPending || !selectedFile}
        >
          {mutation.isPending ? "Uploading..." : "Upload"}
        </Button>
      </form>
    </Form>
  );
};

export default ImportLeadsForm;
