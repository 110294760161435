import { Outlet } from "react-router-dom";
import NewNotificationsBar from "@/components/Misc/NewNotificationsBar";
import AdminMenuBar from "@/components/SideBar/AdminMenuBar";
import EmployeeMenuBar from "@/components/SideBar/EmployeeMenuBar";
import { useDecodeToken } from "@/hooks/useDecodeToken";
import useAuthUserRole from "@/hooks/useAuthRole";

const NewDashboardLayout = () => {
  const decodedToken = useDecodeToken();
  const authUserRole = useAuthUserRole(decodedToken);

  const SideMenuBar =
    authUserRole === "admin" || authUserRole === "master" ? (
      <AdminMenuBar />
    ) : authUserRole === "employee" ? (
      <EmployeeMenuBar />
    ) : null;

  return (
    <div className="container-fluid flex bg-base-200">
      {/* Side Menu Start */}
      {SideMenuBar}
      {/* Side Menu End */}

      {/* Dashboard Layout Start */}
      <div className="flex-1 overflow-y-auto">
        <NewNotificationsBar />
        <div className="p-5 overflow-auto bg-slate-100 min-h-screen">
          <Outlet />
        </div>
      </div>
      {/* Dashboard Layout End */}
    </div>
  );
};

export default NewDashboardLayout;
