import {
  BellPlusIcon,
  FileClockIcon,
  HistoryIcon,
  IndianRupeeIcon,
  LayoutDashboardIcon,
  PhoneOutgoingIcon,
  UserCheck,
} from "lucide-react";

export const EmployeeMenuData = [
  {
    menu_title: "Dashboard",
    menu_link: "/dashboard",
    icon: <LayoutDashboardIcon className="w-4 h-4 mr-2" />,
  },
  {
    menu_title: "Users",
    menu_link: "/crm-leads",
    icon: <UserCheck className="w-4 h-4 mr-2" />,
  },
  {
    menu_title: "Calls",
    menu_link: "/calls",
    icon: <PhoneOutgoingIcon className="w-4 h-4 mr-2" />,
  },
  {
    menu_title: "Reminders",
    menu_link: "/reminders",
    icon: <BellPlusIcon className="w-4 h-4 mr-2" />,
  },
  {
    menu_title: "Payments",
    menu_link: "/payments",
    icon: <IndianRupeeIcon className="w-4 h-4 mr-2" />,
  },
  {
    menu_title: "Activity",
    menu_link: "/employee-activity",
    icon: <HistoryIcon className="w-4 h-4 mr-2" />,
  },
  {
    menu_title: "Time Tracker",
    menu_link: "/time-tracker",
    icon: <FileClockIcon className="w-4 h-4 mr-2" />,
  },
];
