import { createColumnHelper, ColumnDef } from "@tanstack/react-table";
import { formatDate } from "@/lib/utils";
import DeleteCallNote from "@/components/Forms/Lead/DeleteCallNote";
import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { EyeIcon } from "lucide-react";
import { Checkbox } from "@/components/ui/checkbox";

type Call = {
  _id: string;
  note: string;
  user: {
    userId: string;
    first_name: string;
    last_name: string;
    email: string;
  };
  addedBy: {
    first_name: string;
    last_name: string;
  };
  createdAt: string;
  updatedAt: string;
};

const columnHelper = createColumnHelper<Call>();

export const CallsCols = (authUserRole: string): ColumnDef<any, any>[] => [
  columnHelper.display({
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={
          table.getIsAllPageRowsSelected() ||
          (table.getIsSomePageRowsSelected() && "indeterminate")
        }
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
      />
    ),
    cell: (props) => (
      <Checkbox
        checked={props.row.getIsSelected()}
        onCheckedChange={(value) => props.row.toggleSelected(!!value)}
        aria-label="Select row"
      />
    ),
  }),
  columnHelper.accessor("note", {
    header: "Call Summary",
  }),
  columnHelper.accessor("user", {
    header: "User",
    cell: (props) => {
      const firstName = props.row.original.user?.first_name || "";
      const lastName = props.row.original.user?.last_name || "";
      return `${firstName} ${lastName}`.trim();
    },
  }),
  columnHelper.accessor("user.email", {
    header: "User Email",
  }),
  columnHelper.accessor("addedBy", {
    header: "Added By",
    cell: (props) => {
      const firstName = props.row.original.addedBy?.first_name || "";
      const lastName = props.row.original.addedBy?.last_name || "";
      return `${firstName} ${lastName}`.trim();
    },
  }),
  columnHelper.accessor("createdAt", {
    header: "Added On",
    cell: (props) => formatDate(props.getValue()),
  }),
  columnHelper.accessor("updatedAt", {
    header: "Updated On",
    cell: (props) => formatDate(props.getValue()),
  }),
  columnHelper.display({
    id: "actions",
    header: "Actions",
    cell: (props) => {
      const userId = props.row.original.user?.userId;
      const recordId = props.row.original._id;
      return (
        <div className="flex items-center gap-4">
          <Link to={`/crm-leads/lead/${userId}`}>
            <Button
              variant={`outline`}
              className="text-primary hover:bg-primary hover:text-white"
            >
              <EyeIcon className="w-4 h-4 mr-2" /> View
            </Button>
          </Link>
          {authUserRole === "admin" || authUserRole === "master" ? (
            <DeleteCallNote recordId={recordId} />
          ) : null}
        </div>
      );
    },
  }),
];
