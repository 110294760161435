import { crmMenu, dashboardMenu, webMenu, empManageMenu } from "@/data/sidebar";
import { MenuItemComponent } from "./SideBarComponents";

const AdminMenuBar = () => {
  return (
    <div className="w-64 h-screen sticky top-0 p-3">
      {dashboardMenu.map((item, index) => (
        <MenuItemComponent key={index} {...item} />
      ))}
      {webMenu.map((item, index) => (
        <MenuItemComponent key={index} {...item} />
      ))}
      {crmMenu.map((item, index) => (
        <MenuItemComponent key={index} {...item} />
      ))}
      {empManageMenu.map((item, index) => (
        <MenuItemComponent key={index} {...item} />
      ))}
    </div>
  );
};

export default AdminMenuBar;
