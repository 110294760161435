import { EmployeeMenuData } from "@/data/EmployeeMenuData";
import { MenuItemComponent } from "./SideBarComponents";

const EmployeeMenuBar = () => {
  return (
    <div className="w-64 h-screen sticky top-0 p-3">
      {EmployeeMenuData.map((item, index) => (
        <MenuItemComponent key={index} {...item} />
      ))}
    </div>
  );
};

export default EmployeeMenuBar;
